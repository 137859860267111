import { useTheme } from "styled-components"
import { Button, ButtonProps } from "@mui/material"

const PrimaryButton = (props: ButtonProps) => {

	const theme = useTheme();
	return <Button
		style={{
			color: theme.colors.primary.contrastText,
			backgroundColor: theme.colors.primary.main,
			minWidth: '100px',
		}}
		{...props}
	>
		{props.children}
	</Button>

}

export default PrimaryButton