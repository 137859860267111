import toast from "react-hot-toast";
import { useApi } from "./use-api"
import { User } from "@tareticketing/api-client";

export const useProfile = () => {
	const { profileApi } = useApi();


	const recoverAccount = async (): Promise<User | null> => {
		try {
			const res = await profileApi.recoverUser();
			toast.success("Account recovered!")
			return res.data;
		} catch (err: any) {
			toast.error("Error recovering account. Please try again later");
			return null;
		}
	}

	const deleteAccount = async (): Promise<User | null> => {
		try {
			const res = await profileApi.deleteUser();
			toast.success("Account marked for deletion. Account will be deleted in 90 days")
			return res.data;
		} catch (err: any) {
			toast.error("Error recovering account. Please try again later");
			return null;
		}
	}


	return {
		recoverAccount,
		deleteAccount
	}

}
