import { useEffect, ReactNode } from 'react'
import { SignedIn, useUser } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'

interface AuthedPageProps {
	children: ReactNode
}

const AuthedPage = (props: AuthedPageProps) => {
	const { isSignedIn, isLoaded } = useUser()
	const navigate = useNavigate()

	useEffect(() => {
		if (!isSignedIn) navigate('/auth/sign-in')
	}, [isSignedIn])


	if (!isLoaded) return <div>WAITING TO LOAD</div>

	return (
		<SignedIn>{props.children}</SignedIn>
	)
}

export default AuthedPage