import * as React from 'react'
import { SVGProps } from 'react'
const RelaxSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={800} height={800} viewBox="0 0 48 48" {...props}>
    <circle cx={20} cy={12} r={8} fill={props.fill} />
    <path
      fill={props.fill}
      d="M30.971 36h-21.8l-4.585 4.586 2.828 2.828L10.829 40h21.342l3.415 3.414 2.828-2.828-3.385-3.386 8.598-12.038-3.254-2.324zM35.291 19.466a2.978 2.978 0 0 0 2.245.486 2.985 2.985 0 0 0 1.928-1.241 3.006 3.006 0 0 0-.755-4.178 2.993 2.993 0 0 0-2.245-.485 2.983 2.983 0 0 0-1.93 1.244 3.003 3.003 0 0 0 .757 4.174z"
    />
    <path
      fill={props.fill}
      d="m17 28.288 7.735 4.297c.248.32.53.615.872.852a4.002 4.002 0 0 0 5.566-1.008l4.558-6.576a3.976 3.976 0 0 0 .646-2.991 3.97 3.97 0 0 0-1.654-2.574 3.962 3.962 0 0 0-2.992-.646 3.97 3.97 0 0 0-2.574 1.654l-4.558 6.576c-.01.015-.016.032-.026.047L17 23.712 5.28 30.223l1.943 3.497L17 28.288z"
    />
  </svg>
)
export default RelaxSVG
