import styled from 'styled-components'
import { Container } from '../shared'
import { Typography } from '@mui/material'

export const FormView = styled(Container)`
  background-color: white;
  width: 90%;
  max-width: 500px;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
  box-sizing: border-box;
  align-items: stretch;
  justify-content: flex-start; 
`


export const ButtonContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 10px; 

  button {
    flex-grow: 1;
    width: 100%
  } 
`

export const ErrorText = styled(Typography)`
  color: #c73e1d;
  width: 100%;
  padding-left: 10px;
`
