import { useAuth } from '@clerk/clerk-react'
import {
	AuthenticationApi,
	BillApi,
	CompanyApi,
	Configuration,
	CustomerApi,
	DispatchApi,
	EmployeeApi,
	EquipmentApi,
	InvitationsApi,
	ProfileApi,
	RfoApi
} from '@tareticketing/api-client'
import { useMemo } from 'react'

import { API_URL } from '../utils/configs/config'
import axios from 'axios'


axios.defaults.baseURL = API_URL

export const useApi = () => {
	const { getToken } = useAuth()
	const configuration = useMemo(() => {
		return new Configuration({
			basePath: API_URL,
			apiKey: async () => {
				const tok = await getToken()
				if (!tok) return ''
				return tok
			},
			baseOptions: {
				default: {
					baseURL: API_URL
				}
			}
		})
	}, [getToken])

	const companyApi = useMemo(() => {
		return new CompanyApi(configuration, API_URL)
	}, [configuration])

	const authApi = useMemo(() => {
		return new AuthenticationApi(configuration, API_URL)
	}, [configuration])

	const customerApi = useMemo(() => {
		return new CustomerApi(configuration, API_URL)
	}, [configuration])

	const employeeApi = useMemo(() => {
		return new EmployeeApi(configuration, API_URL)
	}, [configuration])

	const dispatchApi = useMemo(() => {
		return new DispatchApi(configuration, API_URL)
	}, [configuration])

	const rfoApi = useMemo(() => {
		return new RfoApi(configuration, API_URL)
	}, [configuration])

	const billApi = useMemo(() => {
		return new BillApi(configuration, API_URL)
	}, [configuration])

	const equipmentApi = useMemo(() => {
		return new EquipmentApi(configuration, API_URL)
	}, [configuration])

	const profileApi = useMemo(() => {
		return new ProfileApi(configuration, API_URL)
	}, [configuration])

	const invitationApi = useMemo(() => {
		return new InvitationsApi(configuration, API_URL)
	}, [configuration])

	return {
		companyApi,
		authApi,
		customerApi,
		employeeApi,
		dispatchApi,
		rfoApi,
		billApi,
		equipmentApi,
		profileApi,
		invitationApi
	}
}
