import { MuiTelInput, MuiTelInputProps } from "mui-tel-input"
import { InputContainer } from "./styles"
import { ErrorText } from "./styles"

export type PhoneInputProps = {
	errorMessage?: string
	touched: boolean
}

const PhoneInput = (props: PhoneInputProps & MuiTelInputProps) => {
	return <InputContainer>
		<MuiTelInput
			{...props}
		/>
		<ErrorText>
			{
				props.touched && props.errorMessage
			}
		</ErrorText>

	</InputContainer>
}


export default PhoneInput