import { useSignIn } from "@clerk/clerk-react";
import { ClerkAPIError, PhoneCodeFactor, } from "@clerk/types";
import toast from "react-hot-toast";

interface UseTareLoginReturn {
	prepareLogIn: (phone: string) => Promise<boolean>;
	login: (phone: string, code: string) => Promise<boolean>
}

function useTareLogin(): UseTareLoginReturn {
	const { signIn, isLoaded, setActive } = useSignIn();

	const prepareLogIn = async (phone: string): Promise<boolean> => {

		if (!isLoaded) {
			toast.error("Sorry and error occurred. Please try again")
			return false
		}

		try {
			const res = await signIn.create({
				identifier: phone
			})

			if (!res.supportedFirstFactors) {
				toast.error("Sorry and error occurred. Please try again")
				return false
			}

			const phoneCodeFactor = res.supportedFirstFactors.find(
				(f) => f.strategy === 'phone_code' && f.safeIdentifier === phone
			) as PhoneCodeFactor | undefined

			if (!phoneCodeFactor) {
				toast.error("Sorry it seems like authentication configuration is not correct. Please try again later")
				return false
			}

			await res.prepareFirstFactor({
				strategy: 'phone_code',
				phoneNumberId: phoneCodeFactor.phoneNumberId
			})

			return true;
		} catch (err: any) {

			if (err.clerkError) {
				const errs: ClerkAPIError[] = err.errors;
				if (errs.some(e => e.code === "form_identifier_not_found"))
					toast.error("Account not found")
			}
			return false
		}
	}


	const login = async (phone: string, code: string): Promise<boolean> => {

		if (!isLoaded) {
			toast.error("Sorry and error occurred. Please try again")
			return false
		}

		try {

			const completeSignIn = await signIn.attemptFirstFactor({
				strategy: 'phone_code',
				code: code
			})

			if (completeSignIn.status === 'complete') {
				await setActive({ session: completeSignIn.createdSessionId })
				toast.success('Login successfull')
				return true
			} else {
				toast.error('There was an error authenticating you')
				return false
			}
		} catch (err: any) {
			let message = "Error validating phone";
			if (err.clerkError) {
				const errs: ClerkAPIError[] = err.errors;
				if (errs.some((e) => e.code === 'form_code_incorrect'))
					message = 'Code incorrect'
				if (errs.some((e) => e.code === 'verification_expired'))
					message = 'Verification expired'
			}

			toast.error(message)
			return false
		}
	}

	return {
		prepareLogIn, login
	}
}


export default useTareLogin