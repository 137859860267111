import * as React from 'react'
import { SVGProps } from 'react'
const OperatorSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fill="none"
    viewBox="0 0 48 48"
    {...props}>
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M17.32 9.032c-1.25 1.72-1.522 3.64-1.189 4.657a1 1 0 1 1-1.9.622c-.59-1.797-.037-4.379 1.47-6.454C17.257 5.717 19.894 4 23.637 4c3.804 0 6.639 1.562 8.376 3.633 1.701 2.028 2.4 4.627 1.747 6.672a1 1 0 1 1-1.905-.61c.39-1.219.016-3.12-1.374-4.776C29.127 7.306 26.855 6 23.636 6c-3.062 0-5.113 1.376-6.316 3.032Z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M19 6a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1ZM24 4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1ZM18.125 29.628a1.1 1.1 0 0 0-1.246-.597C11.474 30.36 6 33.067 6 37.141V44h36v-6.86c0-4.073-5.474-6.78-10.879-8.109a1.1 1.1 0 0 0-1.246.597h-11.75Zm7.686 2h-8.936l-.23-.472c-2.322.627-4.512 1.499-6.129 2.568C8.693 34.931 8 36.1 8 37.141V42h32v-4.86c0-1.04-.693-2.21-2.516-3.416-1.617-1.069-3.807-1.941-6.13-2.568l-.23.472h-5.313Z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill}
      d="M16 35a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0v-7ZM30 35a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0v-7Z"
    />
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M29 6a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1ZM12.568 15.764c-.177-1.26.567-2.444 1.768-3.511l1.328 1.494c-1.049.933-1.149 1.499-1.115 1.739.035.251.288.666 1.185 1.122C17.49 17.5 20.671 18 24 18c3.33 0 6.511-.5 8.266-1.392.898-.456 1.15-.87 1.185-1.122.034-.24-.066-.806-1.115-1.739l1.328-1.494c1.201 1.067 1.945 2.251 1.768 3.511-.176 1.249-1.19 2.084-2.26 2.628C30.989 19.5 27.42 20 24 20c-3.42 0-6.989-.5-9.172-1.608-1.07-.544-2.084-1.38-2.26-2.628Z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M17 18a7 7 0 1 0 14 0h2a9 9 0 1 1-18 0h2Z"
      clipRule="evenodd"
    />
  </svg>
)
export default OperatorSVG
