import * as React from 'react'
import { SVGProps } from 'react'
const ManageSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 32 32"
    {...props}>
    <path
      d="M7 8.05V5.5a.5.5 0 0 0-1 0v2.55c-1.694.245-3 1.688-3 3.45s1.306 3.205 3 3.45V26.5a.5.5 0 0 0 1 0V14.95c1.694-.245 3-1.688 3-3.45S8.694 8.295 7 8.05zM6.5 14A2.503 2.503 0 0 1 4 11.5C4 10.121 5.122 9 6.5 9S9 10.121 9 11.5C9 12.878 7.878 14 6.5 14zm9.5 3.05V5.5a.5.5 0 0 0-1 0v11.55c-1.694.245-3 1.688-3 3.45s1.306 3.205 3 3.45v2.55a.5.5 0 0 0 1 0v-2.55c1.694-.245 3-1.688 3-3.45s-1.306-3.205-3-3.45zM15.5 23a2.503 2.503 0 0 1-2.5-2.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5zM28 14.5a3.49 3.49 0 0 0-3-3.45V5.5a.5.5 0 0 0-1 0v5.55c-1.694.245-3 1.688-3 3.45s1.306 3.205 3 3.45v8.55a.5.5 0 0 0 1 0v-8.55a3.49 3.49 0 0 0 3-3.45zM24.5 17a2.503 2.503 0 0 1-2.5-2.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"
      style={{
        fill: props.fill
      }}
    />
  </svg>
)
export default ManageSVG
