import { useAuth } from "@clerk/clerk-react"
import toast from "react-hot-toast";

interface UseTareLogoutReturn {
	logout: () => Promise<boolean>
}

function useTareLogout(): UseTareLogoutReturn {
	const { signOut } = useAuth();


	const logout = async (): Promise<boolean> => {
		try {
			await signOut()
			toast.success("Logged out. See you soon")
			return true;
		} catch (err: any) {
			toast.error("An error occured when logging out. Try again.")
			return false
		}
	}


	return {
		logout
	}

}

export default useTareLogout