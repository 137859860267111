import { SignedOut } from '@clerk/clerk-react'

type Props = {}

const SignUpPage = (props: Props) => {
	return (
		<SignedOut>
			<div>SignUpPage</div>
		</SignedOut>
	)
}

export default SignUpPage