import { InputContainer } from "./styles"
import { ErrorText } from "./styles"
import { TextFieldProps, TextField } from "@mui/material"

export type TxtFieldProps = {
	errorMessage?: string
	touched: boolean
}

const TextInput = (props: TxtFieldProps & TextFieldProps) => {
	return <InputContainer>
		<TextField
			{...props}
		/>
		<ErrorText>
			{
				props.touched && props.errorMessage
			}
		</ErrorText>

	</InputContainer>
}


export default TextInput