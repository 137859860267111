import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import { createTheme, ThemeProvider as MUIThemeprovider, ThemeOptions, } from '@mui/material'
import { DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import RootPage from './routes/Root/RootPage'
import ErrorPage from './routes/Error/ErrorPage'
import 'intersection-observer'
import { Toaster } from 'react-hot-toast'
import PrivacyPolicyPage from './routes/PrivacyPolicy/privacy-policy'
import { ClerkProvider, } from '@clerk/clerk-react'
import SignInPage from './routes/Auth/SignIn'
import SignUpPage from './routes/Auth/SignUp'
import Dashboard from './routes/Dashboard'
import { API_URL, CLERK_PUBLISHABLE_KEY } from './utils/configs/config'
import Profile from './routes/Profile'


const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#111629',
      contrastText: '#F2E9E4'
    },
    secondary: {
      main: '#ef835d',
      contrastText: '#F2E9E4'
    },
    error: { main: '#C73E1D' },
    background: { default: '#FFFFFF' },
    text: { primary: '#000000' }
  }
}

const theme = createTheme(themeOptions)




export const colors = {
  white: '#fff',
  primary: '#ef835d',
  secondary: '#2c365a',
  tertiary: '#85c6d8',
  gray: '#d1d5db',
  graylight: '#F3F4F6',
  graydark: '#4B5563',
  accent: '#fbcd77',
  success: '#01A971',
  red: 'red'
}

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      { path: '/', element: <RootPage /> },
      { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
      {
        path: '/auth',
        children: [
          { path: '/auth/sign-in', element: <SignInPage /> },
          { path: '/auth/sign-up', element: <SignUpPage /> },
        ]

      }
    ],
    errorElement: <ErrorPage />
  },
  {
    path: '/app',
    children: [
      { path: '/app', element: <Dashboard /> },
      { path: '/app/profile', element: <Profile /> }
    ]
  }
])

if (!CLERK_PUBLISHABLE_KEY)
  throw new Error("Missing clerk publishable key!");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <MUIThemeprovider theme={theme}>
        <StyledComponentsThemeProvider theme={{ colors: themeOptions.palette } as DefaultTheme}>
          <Toaster />
          <RouterProvider router={router} />
        </StyledComponentsThemeProvider>
      </MUIThemeprovider>
    </ClerkProvider>
  </React.StrictMode>
)
