import styled from 'styled-components'
import { Container } from '../../components/shared'

import { Typography } from '@mui/material'
import { SignedOut } from '@clerk/clerk-react'

const View = styled(Container)`
  height: 100%;
  width: 100vw;
  align-items: flex-start;
`

const PrivacyPolicyPage = () => {
  return (
    <SignedOut>
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3" fontWeight="bold">
          Floating Fog Technologies Inc. Privacy Notice
        </Typography>
        <Typography textAlign="justify">
          1. Introduction Floating Fog Technologies Inc. ("Floating Fog Technologies" also referred to
          as "we" or "us") understands and respects the importance of the Personal Information we
          collect to manage our business and maintain commercial relationships. Floating Fog
          Technologies has developed the present privacy notice (the "Notice") to explain how we
          collect, use, and disclose (“Process”) information about identifiable individuals (“Personal
          Information”) such as you. This Notice also describes your Personal Information protection
          rights and how to exercise them. Some provisions may exceed regulatory requirements. In such
          circumstances, Floating Fog Technologies has full discretion with respect to their
          application. 2. Notice Requirements 2.1 Accountability: Who is Responsible for my Personal
          Information? Floating Fog Technologies is responsible for the Personal Information we
          Process. We have appointed a Chief Privacy Officer ("CPO") to oversee our compliance with
          applicable Personal Information protection legislation. 2.1.1 Floating Fog Technologies
          Contact details 16091 10ave Surrey, British Columbia V4A 1A7. 2.2 What Personal Information
          do we Process? Floating Fog Technologies Processes the following Personal Information:
          (a)Identity and contact information: including your name, location, phone number, email
          address and age. (b)Profile and usage information: including passwords to our app or
          password protected platforms or services, your preferences in receiving marketing
          information from us, your communication preferences and information about how you use our
          app including the services you viewed or searched for, page response times, download errors,
          length of visits and interaction information (such as scrolling, clicks, and mouse-overs).
          (c)Technical information: including information collected during your visits to our app, the
          Internet Protocol (IP) address, login data, browser type and version, device type, time zone
          setting, browser plug-in types and versions, operating systems and platform. Please note: If
          you provide Personal Information to us about anyone other than yourself, you must ensure
          that they understand how their information will be used, and that they have given their
          permission for you to disclose it to us and for you to allow us, and our outsourced service
          providers, to use it. 2.3 Why do we Process your Personal Information? Floating Fog
          Technologies processes your Personal Information to manage our business and to maintain and
          develop commercial relationships. We only Process such information as is necessary for those
          purposes. If we are required to Process your Personal Information for a purpose that differs
          from the purposes described herein, we will obtain your consent before doing so except in
          those circumstances set out in Section 2.8 of the Notice. Purposes for which we Process
          Personal Information are as follows: (a) As required by us to conduct our business and
          pursue our legitimate interests in particular: (i) to provide our services and to respond to
          and address any questions or concerns relating to our services; (ii) to analyse and improve
          our services and communications including our app operations and to monitor compliance with
          our policies and standards; (iii) to manage access to our premises and for security
          purposes; (iv) to protect the security of our communications and other systems and to
          prevent and detect security threats, frauds or other criminal or malicious activities; (v)
          for insurance purposes; and (vi) to collect information about your preferences to
          personalize and improve the quality of our communications with you; and (b) As required by
          us to conduct marketing or other promotional activity including to communicate promotional
          material that you have requested and to which you may unsubscribe by clicking on the
          Unsubscribe link or following the Unsubscribe instructions included in every mail; (c) As
          required to comply with any legal duties or to take legal action including: (i) to protect
          our rights and safety as well as that on any third parties; (ii) to comply with any legal
          requirements or court orders; (iii) to prosecute and defend a court, arbitration, or similar
          proceeding; and (iv) to communicate this information to any advisors who assists us in
          complying with legal, accounting, or security requirements, and (d) as otherwise permitted
          by law. Floating Fog Technologies will only provide you with marketing related information
          after you have, where legally required to do so, opted to receive that communication, and
          having provided the opportunity for you to opt out at any time. Floating Fog Technologies
          will not use your Personal Information for taking any automated decisions affecting or
          creating profiles other than as described above. Floating Fog Technologies will not use
          Personal Information for a purpose other than for which it was collected except with your
          consent unless: (a) legal, medical or security reasons make it impossible to obtain consent;
          or (b) Floating Fog Technologies is permitted or required to do so by applicable laws. 2.4
          How do we collect your Personal Information? The circumstances in which Floating Fog
          Technologies may collect Personal Information about you include: (a) when you use our app;
          (b) when you provide services to us; (c) when you correspond with us by phone, email or
          other electronic means, or in writing, or when you provide other information directly to us,
          including in conversation with our employees; (d) when you or your organization browse,
          complete a form (including information reporting a problem with the app) or make an inquiry
          or otherwise interact on our app or other online platforms you are willingly providing
          information which could be included in the collection of Personal Information. Depending on
          the circumstances, this may include Personal Information such as, but not limited to,
          physical mailing address, personal email address, phone number(s), and other details like
          gender, occupation, and other demographic information. More information on how our app
          Processes Personal Information can be found in section 2.7 below; and 2.5 What Happens if I
          don’t Provide my Personal Information? Where we need to collect Personal Information by law
          or in order to Process your instructions or perform a contract we have with you and you fail
          to provide that Personal Information when requested, we may not be able to carry out your
          instructions or perform the contract we have or are trying to enter into with you. In this
          case, we may have to cancel our engagement or contract you have with us, but we will notify
          you if this is the case at the time. 2.6 How much Personal Information do we Require?
          Floating Fog Technologies limits the Personal Information we Process to what is necessary
          for managing our business and maintaining and developing our commercial relationships. 2.7
          How is your Personal Information Processed when using our App? 2.7.1 Information Collected
          from your Use of Our App To ensure our App is optimized for ease of use, and/or for future
          communications, Floating Fog Technologies or our service provider(s) may collect standard
          information about how and when you use the app. This information may include but is not
          limited to your IP address and location data, weblogs, time, date, language preferences,
          browser used, referring web addresses, other communication data, searches conducted and
          pages visited. Our email marketing communications may contain a single, campaign-unique "web
          beacon pixel" to tell us whether our email is opened and combined with other technology to
          verify any clicks through to links within the email. We may use the information for purposes
          including determining which of our email is more interesting to you and to query whether
          users who do not open our email wish to continue receiving them. The pixel will be deleted
          when you delete the email. If you do not wish the pixel to be downloaded to your device,
          through email you can opt not to download images from Floating Fog Technologies. You may
          also choose not to click in the email marketing communications that we send you. This
          information may be connected to your personal identity. 2.7.2 Social Media Widgets Floating
          Fog Technologies’ app may include widgets, such as the "Share This" button or interactive
          mini-programs that run on our app. Social media features and widgets are either hosted by a
          third party or hosted directly on our app. Your interactions with these features are
          governed by the privacy policy of the company providing such service. 2.7.3 Floating Fog
          Technologies’ app includes links to other apps or internet sites, the privacy practices of
          which may differ from ours. If you submit Personal Information to any of those apps or
          internet sites, your information is governed by the Personal Information protection policies
          of those apps or internet sites, and we do not accept any responsibility or liability in
          respect of third-party apps or sites. Please check the privacy policies published on any
          third-party app or site which you may access through our app before submitting Personal
          Information to them. 2.8 Do we Share Your Personal Information? Floating Fog Technologies
          shares your Personal Information only in the following circumstances and with the following
          recipients: (i) on a confidential basis with third parties for the purpose of collecting
          your feedback on our service, to help us measure our performance and to improve and promote
          our services; (ii) with companies providing services for money laundering and terrorist
          financing checks, credit risk reduction and other fraud and crime prevention purposes and
          companies providing similar services, including financial institutions, credit reference
          agencies and regulatory bodies with whom such Personal Information is shared; (iii) with
          courts, law enforcement authorities, regulators, government officials or attorneys or other
          parties where it is reasonably necessary for the establishment, exercise or defense of a
          legal or equitable claim, or for the purposes of a confidential alternative dispute
          resolution process; (iv) with service providers who we engage within or outside of Floating
          Fog Technologies, domestically or abroad, (e.g. shared service centers), to Process Personal
          Information for any of the purposes listed above on our behalf and in accordance with our
          instructions only; and (v) if we engage in a commercial or business transaction - as defined
          by applicable privacy legislation - in which case we may disclose your Personal Information
          to the prospective seller or buyer of such business or assets to whom we assign or novate
          any of our rights and obligations. Please note: Floating Fog Technologies, its third-party
          service providers and other third parties to whom we disclose information under the Notice
          may perform activities outside of Canada. Any Personal Information that is Processed in
          countries outside of Canada may be subject to the laws of those countries (e.g. where a
          third party service provider operates internationally). As a result, Personal Information
          may be disclosed in response to valid demands or requests from government authorities,
          courts, or law enforcement officials in countries outside of Canada. You may seek additional
          information regarding the Processing of your Personal Information outside of Canada by
          contacting our CPO at the contact details found in Subsection 2.1.2 above 2.9 How long do we
          Retain your Personal Information? Floating Fog Technologies will not retain Personal
          Information longer than is necessary to fulfil the purpose for which it was collected
          including for the purposes of satisfying any legal, accounting, or reporting requirements
          and, where required for us to assert or defend against legal claims, until the end of the
          relevant retention period or until the claims in question have been settled. As this purpose
          varies depending upon the Personal Information involved, we invite you to contact our CPO
          for any inquiries pertaining specifically to you. Inactive files will be kept 12 months
          after the last recorded date of activity. Upon expiry of the applicable retention period we
          will securely destroy your Personal Information in accordance with applicable laws and
          regulations and with our document retention policy. 2.10 How do we Ensure your Personal
          Information is Accurate? Floating Fog Technologies uses reasonable efforts to ensure that
          your Personal Information is kept as accurate, complete and up to date as possible. We do
          not routinely update your Personal Information, unless such an update is necessary. In order
          to help us maintain and ensure that your Personal Information is accurate and up to date,
          you must inform us, without delay, of any change in the information you provide to us. 2.11
          How do we Safeguard your Personal Information? Floating Fog Technologies is committed to
          protecting the confidentiality, integrity, and availability of your Personal Information. We
          employ security safeguards that are customary in the gaming sector and appropriate to the
          sensitivity of the information, including physical, organizational, and technological
          measures. Examples of such measures include training of personnel, limiting access to
          information on a "need-to-know" basis, using passwords and well-defined internal policies
          and practices. Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your Personal Information, we cannot
          guarantee the security of data transmitted to our app. Any transmission you provide is at
          your own risk. Once we have received your information, we will apply our security practices
          to try to prevent unauthorized access, use and/or disclosure. Floating Fog Technologies may
          store and Process your Personal Information at our offices in Toronto. We use contractual
          and other means to provide a comparable level of protection while information is being
          stored, handled, and Processed using any third party service providers. 2.12 Whom to Contact
          with Questions Depending on the jurisdiction in which you reside, you have various rights
          with respect to our use of your Personal Information: (a) Access: You have the right to
          request whether we hold Personal Information on you and to request a copy of such
          information. To do so, please contact us at the details provided at Subsection 2.1.2.There
          are exceptions to this right, so that access may be denied if, for example, making the
          information available to you would reveal Personal Information about another person, or if
          we are legally prevented from disclosing such information. (b) Accuracy: We aim to keep your
          Personal Information accurate, current, and complete. We encourage you to contact us to let
          us know if any Personal Information is not accurate or changes, so that we can update your
          Personal Information. (c) Withdraw Consent: If you have provided your consent to the
          Processing of your Personal Information, you have the right to fully or partly withdraw your
          consent. To withdraw your consent please follow the opt-out links on any marketing message
          sent to you or contact the CPO. Once we have received notification that you have withdrawn
          your consent, we will no longer Process your information for the purpose(s) to which you
          originally consented unless there is another legal ground for the Processing. Withdrawal of
          consent to receive marketing communications will not affect the Processing of Personal
          Information for the provision of our services. (d) Cessation of Dissemination: You have the
          right to request that we cease disseminating your Personal Information if the dissemination
          is contrary to the law or a court order. You also have the right to request that we cease
          disseminating your Personal Information where the following conditions are met: (i) the
          dissemination of the information causes you serious injury in relation to your right to have
          your reputation or privacy respected; (ii) the injury is clearly greater than the public’s
          interest in knowing the information or the interest of any person’s right to express
          themselves freely; and (iii) the cessation of dissemination requested does not exceed what
          is necessary for preventing the perpetuation of the injury. (e) De-indexation: You have the
          right to request that we de-index any hyperlink attached to your name that provides access
          to information by a technological means if the dissemination is contrary to the law or a
          court order. You also have the right to request that we de-index a link providing access to
          information where the following conditions are met: (i) the dissemination of the information
          causes you serious injury in relation to your right to have your reputation or privacy
          respected; (ii) the injury is clearly greater than the public’s interest in knowing the
          information or the interest of any person’s right to express themselves freely; and (iii)
          the cessation of dissemination requested does not exceed what is necessary for preventing
          the perpetuation of the injury. (f) Re-indexation: You also have the right to request that
          we re-index a link providing access to information where the following conditions are met:
          (i) a failure to do so causes you serious injury in relation to your right to have your
          reputation or privacy respected; (ii) the injury caused by a failure to re-index is greater
          than the public’s interest in knowing the information or the interest of any person’s right
          to express themselves freely; and (iii) the re-indexation requested does not exceed what is
          necessary for preventing the perpetuation of the injury. (g) Mobility: You have the right to
          request that computerized Personal Information collected from you be communicated to you in
          a commonly used technological format as well as to any person or body authorized by law to
          collect such information. This right does not extend to information that was created or
          inferred from your Personal Information and we are under no obligation to communicate such
          information if doing so raises serious practical difficulties. (h) Complaints: If you
          believe that your Personal Information protection rights may have been violated, you have
          the right to lodge a complaint with the applicable supervisory authority, or to seek a
          remedy through the courts. You may enquire about your Personal Information by contacting our
          CPO or making a request to view your Personal Information by clicking here. Floating Fog
          Technologies will generally respond to all access requests within 30 days of the receipt of
          all necessary information. In circumstances where we are not able to provide access, or if
          additional time is required to fulfill a request, we will advise you in writing. Floating
          Fog Technologies may not release certain types of information based upon exemptions
          specified in applicable laws. Where possible, we will sever the information that will not be
          disclosed and provide you with access to the remaining information. Should we be unable to
          provide access to or disclose Personal Information to you, we will provide you with an
          explanation, subject to restrictions. In certain circumstances, such as where the request is
          excessive or unfounded, we may charge you an administration fee for access to your Personal
          Information. We may also charge for additional copies. We will advise you of any fees before
          proceeding with a request. 2.14 How do we Resolve your Concerns? Any report, concern,
          complaint or incident of which we become aware that involves conduct that may contravene the
          Notice will be treated confidentially to the extent possible. However, some disclosure may
          be necessary to adequately address issues raised, aid enquiry and implement solutions, as
          appropriate. 2.14.1 Incident Reporting and Escalation Process We take all potential or
          actual Personal Information breaches seriously. You may report your concerns about the
          Processing of your Personal Information directly to our CPO. The CPO will engage the
          appropriate levels of management to assist with resolution of the issue. 2.14.2 Alternative
          Actions While we are committed to resolving all Personal Information matters internally,
          nothing in the Notice precludes you from contacting the appropriate federal or provincial
          privacy commissioner. 2.14.3 Prohibition Against Retaliation We will not retaliate against a
          Person who, in good faith and on the basis of reasonable belief, raises questions or
          concerns regarding their Personal Information. 3. Maintenance and Review The Notice is
          effective May 1, 2023, and supersedes all prior versions. Floating Fog Technologies reserves
          the right to interpret the Notice at its sole discretion and to make changes as it deems
          appropriate from time to time without the requirement of advanced notice. Personal
          Information protection legislation continues to evolve and, as a result, this Notice may be
          amended by us from time to time at our full discretion without any prior notice or liability
          to you or any other persons. The Processing of your Personal Information by us will be
          governed by the version of this Notice in effect at that time
        </Typography>
      </View>
    </SignedOut>
  )
}

export default PrivacyPolicyPage
