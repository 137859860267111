import { useTheme } from "styled-components";
import { Button, ButtonProps } from '@mui/material';
import useTareLogout from "../../../hooks/use-logout";
import { useState } from "react";

const LogoutButton = (props: ButtonProps) => {
	const [loading, setLoading] = useState<boolean>(false)
	const theme = useTheme();
	const { logout } = useTareLogout();

	const handleLogout = async () => {
		setLoading(true)
		await logout()
		setLoading(false)
	}
	return <Button
		onClick={handleLogout}
		style={{
			backgroundColor: 'transparent',
			border: `1px solid ${theme.colors.error.main}`,
			color: theme.colors.error.main
		}}
		{...props}
	>
		{loading ? "Logging out" : (props.children ?? 'Logout')}
	</Button>
}

export default LogoutButton