import styled from 'styled-components'
import { Typography, useTheme } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import { device } from '../../../../components/devices'

import { colors } from '../../../../index'

export const FEATURES_ID = 'features'
export const GET_STARTED_ID = 'get_started'
export const PRICING_ID = 'pricing'
export const DOWNLOAD_ID = 'download'
export const CONTACT_ID = 'contact'

const Footer = () => {
  const isDesktop = useMediaQuery({ query: device.laptopL })
  const theme = useTheme()

  return (
    <FooterContainer style={{ backgroundColor: colors.graydark }}>
      <a
        href={`/privacy-policy`}
        style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
        <Typography
          variant={isDesktop ? 'h6' : 'subtitle1'}
          fontWeight={'bold'}
          color={colors.graylight}>
          Privacy Policy
        </Typography>
      </a>
    </FooterContainer>
  )
}

export const FooterContainer = styled.nav<{}>`
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    height: 80px;
  }
`

export default Footer
