import { useTheme } from "styled-components"
import AuthedPage from "../../components/AuthedPage"
import { Container } from "../../components/shared"
import SecondaryButton from "../../components/Molecules/Buttons/secondary-button"
import { useEffect, useState } from "react"
import { User } from "@tareticketing/api-client"
import { useProfile } from "../../hooks/use-profile"
import { useApi } from "../../hooks/use-api"
import { useAuth } from "@clerk/clerk-react"
import LogoutButton from "../../components/Molecules/Buttons/logout-button"

const ProfilePage = () => {
	const [user, setUser] = useState<User | undefined>(undefined);
	const theme = useTheme();
	const { authApi } = useApi();
	const { recoverAccount, deleteAccount } = useProfile()


	const handleDelete = async () => {
		const res = await deleteAccount();
		if (res) setUser(user);
	}

	const handleRecoverAccount = async () => {
		const res = await recoverAccount();
		if (res) setUser(res);
	}

	const initializePage = async () => {
		const res = await authApi.getCurrentlyLoggedInUser()
		res && setUser(res.data.u);
	}

	useEffect(() => {
		initializePage();
	}, [])
	return (
		<AuthedPage>
			<Container style={{
				backgroundColor: theme.colors.primary.main,
				minHeight: '100vh'
			}}>
				{
					user &&
					<SecondaryButton
						onClick={() => user?.toDelete ? handleRecoverAccount() : handleDelete()}
					>
						{user?.toDelete ? 'Recover Account' : 'Delete Account'}
					</SecondaryButton>
				}
				<LogoutButton />

			</Container>
		</AuthedPage>
	)
}

export default ProfilePage