import { SignedOut } from '@clerk/clerk-react'
import React from 'react'

type Props = {}

const ErrorPage = (props: Props) => {
  return <SignedOut>
    <div>ErrorPage</div>
  </SignedOut>
}

export default ErrorPage
