import styled, { useTheme } from "styled-components"
import { Button, ButtonProps } from "@mui/material"

const SecondaryButton = (props: ButtonProps) => {

	const theme = useTheme();
	return <Button
		style={{
			color: theme.colors.secondary.contrastText,
			backgroundColor: theme.colors.secondary.main,
			minWidth: '100px'
		}}
		{...props}
	>
		{props.children}
	</Button>

}

export default SecondaryButton