import styled from "styled-components"
import { Container } from "../../../components/shared"
import LoginForm from "../../../components/Organisms/Forms/login-form"
import useTareLogin from "../../../hooks/use-login"
import { useAuth } from "@clerk/clerk-react"
import { useEffect } from "react"
import { navigateToDashboard } from "../../../utils/navigator"
import { useLocation, useNavigate } from "react-router-dom"

const SignInPageContainer = styled(Container)`
	background-color: ${(props) => props.theme.colors.primary.main}; 
	min-height: 100vh;

`

const SignInPage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search)

	const { login, prepareLogIn } = useTareLogin();
	const navigate = useNavigate();
	const { isSignedIn } = useAuth()

	console.log(queryParams.get('next'))

	const handleSubmit = async ({ phone, code }: { phone: string; code?: string }): Promise<boolean> => {
		if (code) {
			const res = await login(phone, code);

			if (res)
				console.log('FACK')

			return res;
		} else {
			return await prepareLogIn(phone)
		}
	}

	useEffect(() => {
		if (isSignedIn) {
			const next = queryParams.get('next')
			next ? navigate(next) : navigateToDashboard(navigate);
		}
	}, [isSignedIn, navigate])

	return (
		<SignInPageContainer>
			<LoginForm
				onSubmit={handleSubmit}
			/>
		</SignInPageContainer>
	)
}

export default SignInPage