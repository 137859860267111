import { FormikHelpers, useFormik } from "formik";
import { FormProps } from "./types";
import { ButtonContainer, FormView } from "./styles";
import PhoneInput from "../../Molecules/Inputs/phone-input";
import * as Yup from 'yup';
import { useState } from "react";
import TextInput from "../../Molecules/Inputs/text-input";
import PrimaryButton from "../../Molecules/Buttons/primary-button";
import SecondaryButton from "../../Molecules/Buttons/secondary-button";


const LoginFormValidationSchema = Yup.object().shape({
	phone: Yup.string()
		.length(10, 'Phone number must be 10 character long')
		.required('Phone number is required')
})


interface LoginFormResults {
	phone: string,
	code?: string
}



const LoginForm = (props: FormProps<LoginFormResults>) => {

	const [showOTP, setShowOTP] = useState<boolean>(false)
	const [countryCode, setCountryCode] = useState<string>("+1")

	const onSubmitMiddleMan = async (values: LoginFormResults, helpers: FormikHelpers<LoginFormResults>) => {

		if (showOTP && !values.code) {
			helpers.setErrors({
				code: "Code required"
			})
			return;
		}

		if (showOTP && values.code && values.code.length !== 6) {
			helpers.setErrors({
				code: "Code must be six characters"
			})
			return;
		}


		helpers.setSubmitting(true);
		const res = await props.onSubmit({
			phone: '+' + (countryCode ?? '1') + values.phone,
			code: showOTP ? values.code : undefined
		});
		helpers.setSubmitting(false);

		if (!res) return;

		if (!showOTP) {
			helpers.setFieldValue('code', '', false)
			setShowOTP(true);
		}

	}

	const {
		handleSubmit,
		handleChange,
		handleBlur,
		values,
		errors,
		touched,
		isSubmitting,
		setFieldValue
	} = useFormik({
		onSubmit: onSubmitMiddleMan,
		initialValues: {
			phone: '+1'
		} as LoginFormResults,
		validationSchema: LoginFormValidationSchema
	})

	const handleCancel = () => {
		if (showOTP) {
			setShowOTP(false)
			setFieldValue('code', '', false);
			return;
		}

		props.onCancel && props.onCancel();
	}

	return <FormView>
		<PhoneInput
			label="Phone number"
			error={!!errors.phone}
			value={values.phone}
			onClick={handleBlur('phone')}
			onChange={(e, i) => {
				setCountryCode(i.countryCallingCode ?? "")
				handleChange('phone')(i.nationalNumber ?? '')
			}}
			defaultCountry={"CA"}
			forceCallingCode
			touched={!!touched.phone}
			errorMessage={errors.phone}
		/>
		{showOTP &&
			<TextInput
				label="OTP Code"
				error={!!errors.code}
				onClick={handleBlur('code')}
				onChange={handleChange('code')}
				touched={!!touched.code}
				errorMessage={errors.code}
			/>
		}
		<ButtonContainer>
			<PrimaryButton
				onClick={() => handleSubmit()}
				disabled={isSubmitting}
			>
				{showOTP ? "Authenticate" : (isSubmitting ? 'loading' : props.onSubmitButtonText ?? 'login')}
			</PrimaryButton>
			{(showOTP || props.onCancel) &&
				<SecondaryButton onClick={handleCancel}>
					{showOTP ? "Back" : (props.onCancelButtonText ?? 'Cancel')}
				</SecondaryButton>

			}
		</ButtonContainer>

	</FormView>
}

export default LoginForm