import Hero from './components/Hero/Hero'
import Features from './components/Features/Features'
import Steps from './components/Steps/Steps'
import CallToAction from './components/CallToAction'
import Contact from './components/Contact'
import Header, {
  DOWNLOAD_ID,
  FEATURES_ID,
  GET_STARTED_ID,
  CONTACT_ID
} from './components/NavBar/Navbar'
import Footer from './components/footer/footer'
import { SignedOut } from '@clerk/clerk-react'

const RootPage = () => (
  <SignedOut>
    <Header />
    <Hero />
    <Steps id={`${GET_STARTED_ID}`} />
    <Features id={`${FEATURES_ID}`} />
    {/* <Pricing id={`${PRICING_ID}`} /> */}
    <CallToAction id={`${DOWNLOAD_ID}`} />
    <Contact id={`${CONTACT_ID}`} />
    <Footer />
  </SignedOut>
)

export default RootPage
