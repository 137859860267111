import { Typography } from "@mui/material";
import styled from "styled-components";


export const InputContainer = styled.div`
	display: flex; 
	flex-direction: column; 
	gap: 10px; 
	flex-grow: 1
`


export const ErrorText = styled(Typography)`
  color: ${props => props.theme.colors.error.main};
  width: 100%;
`
