import { useTheme } from "styled-components"
import AuthedPage from "../../components/AuthedPage"
import { Container } from "../../components/shared"
import SecondaryButton from "../../components/Molecules/Buttons/secondary-button"
import { navigateToProfile } from "../../utils/navigator"
import { useNavigate } from "react-router-dom"

const Dashboard = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<AuthedPage>
			<Container style={{
				backgroundColor: theme.colors.primary.main,
				minHeight: '100vh'
			}}>
				<SecondaryButton
					onClick={() => navigateToProfile(navigate)}
				>
					Profile
				</SecondaryButton>
			</Container>
		</AuthedPage>
	)
}

export default Dashboard